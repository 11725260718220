import React, { useRef, useEffect } from 'react';
function Canvas(props) {

    const canvasRef = useRef(null);
    let table = {
        r: [0.488,0.53],
        y: [0.445,0.47],
        g: [0.40,0.43],
        b: [0.37,0.39]
    }
    let number = 50;
    if(props.color) {
        number = Math.random() * (table[props.color[0]][1] - table[props.color[0]][0]) + table[props.color[0]][0];
    }
    console.log(number);
    const draw = (ctx, frameCount, img) => {
        ctx.save()
        ctx.setTransform(1, 0, 0, 1, 0, 0);
        ctx.clearRect(0,0,ctx.canvas.width,ctx.canvas.height);
        ctx.restore()
        ctx.save();
        ctx.translate(ctx.canvas.width/2,ctx.canvas.height/2);
        ctx.rotate((0 + img.rotation) / 180)
      ctx.drawImage(img, -img.width/2, -img.height/2);
      ctx.restore()
        img.rotation += (50 - frameCount * number)
        
      }

    useEffect(() => {
        const canvas = canvasRef.current
        const context = canvas.getContext('2d')
        let frameCount = 0
        let animationFrameId
        var img = new Image();
        img.onload = function() {
          context.drawImage(img, 0, 0);
        }
        img.src = "https://i.imgur.com/ojz3MzN.png"
        img.rotation = 0;
        //Our draw came hereeeee
        const render = () => {
            frameCount++
            if( (50 - frameCount * number) > 0) {
                draw(context, frameCount, img)
            }

            animationFrameId = window.requestAnimationFrame(render)
        }
        render()

        return () => {
            window.cancelAnimationFrame(animationFrameId)
        }
    },[draw])
    return <canvas ref={canvasRef} {...props}></canvas>
}
export default Canvas;