/* eslint-disable eqeqeq */
import logo from './Untitled-1.png';
import './App.css';
import io from 'socket.io-client';
import { useState, useEffect, useCallback } from 'react';
import Canvas from './Canvas';
const socket = io('YellowishLiquidEmbed.bananasquares.repl.co:8099', { transports: ['websocket'] });

function App() {
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [picked, setPicked] = useState();
  const [toggle, setToggle] = useState(false);
  const [color, setColor] = useState(null);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  useEffect(() => {
    socket.on('connect', () => {
      setIsConnected(true);
    });
    socket.on('disconnect', () => {
      setIsConnected(false);
    })
    socket.on('picked', (msg) => {
      setToggle(!toggle);
      let str = '';
      if(msg[0] == 'r') {
        str = '🟥'
      }
      if(msg[0] == 'g') {
        str = '🟩'
      }      if(msg[0] == 'b') {
        str = '🟦'
      }      if(msg[0] == 'y') {
        str = '🟨'
      }
      setPicked(str);
      forceUpdate();
      setColor([msg[0],msg[1]]);
    })

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('picked');
      socket.off('success');
    }
  }, [])


  if(!color) {
    return  <div className='h-100 w-full flexbox items-center justify-center bg-teal-lightest font-sans flex-column'>
    <h1 className='text-6xl text-center font-semibold'>EJJ House Picker</h1>
    <h5 className='text-2xl text-slate-500 text-center font-medium'>by Ewan Fox</h5>
    <div className="h-100 w-full flex items-center justify-center bg-teal-lightest font-sans">
    </div>
    </div>
  }

  if (isConnected) {
    return (
      <div className='h-100 w-full flexbox items-center justify-center bg-teal-lightest font-sans flex-column'>
      <h1 className='text-6xl text-center font-semibold'>EJJ House Picker</h1>
      <h5 className='text-2xl text-slate-500 text-center font-medium mb-0'>by Ewan Fox</h5>
      <div className='h-8'></div>
      <div className="h-100 w-full flex items-center justify-center bg-teal-lightest font-sans flex-col flexbox">
            <div className='bg-black w-2 h-20' width="2" height="36"></div>
            <Canvas width="870" height="870" color={color} ></Canvas>
      </div>
      </div>
    )
  }
  return (
    <div className="App">
      <h1>Failed to connect to backend!</h1>
    </div>
  );
}

export default App;
